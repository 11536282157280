<template>
  <div>
    <!-- v-dialog baslıyor -->
    <v-app>
      <!-- v-dialog bitti -->
      <div class="page-card">
        <v-container>
          <v-row>
            <v-col md="12">
              <v-row>
                <v-col>
                  <v-form v-model="isFormValid">
                    <v-row>
                      <v-col md="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                required
                                v-model="selected.name"
                                label="Adı *"
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.username"
                                label="Kullanıcı Adı *"
                                filled
                                :rules="rules.name"
                              ></v-text-field
                            ></v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col md="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.surname"
                                label="Soyadı *"
                                filled
                                :rules="rules.name"
                              ></v-text-field
                            ></v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.email"
                                label="E-posta *"
                                filled
                                :rules="rules.email"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col md="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                auto-select-first
                                clearable
                                label="Ülke Seçiniz..."
                                v-model="selected.country"
                                filled
                                
                                :items="countryitems"
                                item-text="countryName"
                                item-value="countryId"
                                @change="getCityByCountryId()"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                auto-select-first
                                clearable
                                label="İlçe Seçiniz..."
                                v-model="selected.district"
                                filled
                                
                                :items="districtitems"
                                item-text="townName"
                                item-value="townId"
                                no-data-text="Lütfen Önce İl Seçiniz"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col md="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                auto-select-first
                                clearable
                                label="İl Seçiniz..."
                                v-model="selected.city"
                                filled
                                
                                :items="cityitems"
                                item-text="cityName"
                                item-value="cityId"
                                @change="getDistrictItems(selected.city)"
                                no-data-text="Lütfen Önce Ülke Seçiniz"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.postCode"
                                label="Posta kodu"
                                filled
                                :rules="rules.postcode"
                                maxlength="5"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col md="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.phoneNumber"
                                label="Cep Telefonu *"
                                filled
                                :rules="rules.name"
                                maxlength="10"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="send()"
                          :disabled="!isFormValid"
                        >
                          Kaydet
                        </button>
                      </v-col>
                      <v-col md="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.landPhone"
                                label="Sabit Telefon"
                                filled
                                maxlength="10"
                                :rules="rules.phone"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col md="6">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-textarea
                                v-model="selected.address"
                                label="Adres"
                                filled
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "profile",
  data() {
    return {
      isFormValid: false,
      globaladmin: false,
      cro: false,
      user: false,
      //date: new Date().toISOString().substr(0, 10),
      menu: false,
      rules: {
        name: [(val) => (val || "").length > 0 || "Bu alan doldurulmalı"],
        postcode: [
          (v) => {
            var regex = /[0-9]{5}/;
            return regex.test(v) || v.length == 0 || "Geçersiz Posta Kodu";
          },
        ],
        phone: [
          (v) => {
            var regex = /[0-9]{10}/;
            return (
              regex.test(v) || v.length == 0 || "Geçersiz Telefon Numarası"
            );
          },
        ],
        email: [
          (v) => {
            const pattern = /^([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            return (
              pattern.test(v) ||
              v.length == 0 ||
              "Lütfen Geçerli E-posta Adresi Giriniz."
            );
          },
        ],
      },

      selected: {
        name: "",
        surname: "",
        username: "",
        phoneNumber: "",
        landPhone: "",
        email: "",
        country: null,
        city: null,
        district: null,
        postCode: "",
        address: "",
      },
      countryitems: [],
      cityitems: [],
      districtitems: [],
      selectedCountryId: "",
      selectedCityId: "",
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Hesap Ayarlarım" }]);
    this.getCurrentUserInfo(this.currentUser.id);
  },
  methods: {
    getCurrentUserInfo(userId) {
      ApiService.get("/user", userId)
        .then(({ data }) => {
         
          this.getCountryItems();
         
          console.log("User bilgileri geldi", data);
          this.selected = data.result;
          this.selected.city = data.addResult[1].cityId;
          this.selected.country = data.addResult[0].countryId;
          this.selected.district = data.addResult[2].townId;
          

          this.getCity(this.selected.country);
          this.getDistrictItems(this.selected.city);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    send() {
      let body = {
        name: this.selected.name,
        surname: this.selected.surname,
        username: this.selected.username,
        phoneNumber: this.selected.phoneNumber,
        landPhone: this.selected.landPhone,
        email: this.selected.email,
        country: this.selected.country,
        city: this.selected.city,
        district: this.selected.district,
        postCode: this.selected.postCode,
        address: this.selected.address,
      };
     
      ApiService.put("/userowninfo", body)
        .then(() => {
         
          this.currentUser.fullName = body.name + " " + body.surname;
          this.currentUser.email = body.email;
          Swal.fire({
            icon: "success",
            title: "Bilgileriniz Güncellendi.",
            showConfirmButton: false,
            timer: 1200,
          });
          this.getCurrentUserInfo(this.currentUser.id);
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Bilgileriniz Güncellenemedi.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getCountryItems() {
      ApiService.get("countries")
        .then(({ data }) => {
          this.countryitems = data;
         
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCity(selectedCountryId) {
      if (selectedCountryId == null) {
        this.districtitems = [];
      }
      ApiService.get("cities", selectedCountryId)
        .then(({ data }) => {
          this.cityitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCityByCountryId() {
      this.selected.city = "";
      this.selected.district = "";
      this.districtitems = [];
    
      if (this.selected.country == null) {
        this.cityitems = [];
      }
      this.getCity(this.selected.country);
    },
    getDistrictItems(selectedCityId) {
      if (selectedCityId == null) {
        this.districtitems = [];
      }
      ApiService.get("towns", selectedCityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getDistrictItemsByCityId(changeSelectedCity) {
      let changeSelectedCityId = this.cityitems.filter(
        (city) => city.cityName == changeSelectedCity
      );
      this.selected.district = "";
      ApiService.get("towns", changeSelectedCityId[0].cityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    ...mapGetters(["isGlobalAdmin", "isCro", "isNormalUser", "currentUser"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
